import { BaseParams, GenerativeModel, Part } from "@google/generative-ai";
import { SessionHistory } from "../store/sessions";
import { asyncSleep } from "./asyncSleep";

const HOST = 'api.jd2jl7.z12.tw';
const PORT = 443;
const URI = `wss://${HOST}:${PORT}/api/v1/stream`;

interface ConversationTurn {
    role: string;
    content: string;
}
  
export const getAiChats = async (
    model: GenerativeModel,
    history: SessionHistory[],
    prompts: string | Array<ConversationTurn>,
    stream: boolean,
    options: BaseParams,
    onChatMessage: (message: string, end: boolean) => void
) => {
    console.log(prompts);

    // Replace 'URI' with your actual WebSocket server URL
    const ws = new WebSocket(URI);
    
    // Handle errors during connection establishment
    ws.onerror = (error: object) => {
        console.error(`WebSocket connection error: ${error}`);
        // Handle error in your application, e.g., notify user
    };
    
    // Once the connection is open, send your message
    ws.onopen = () => {
        const message = JSON.stringify({ prompt: `A chat between a curious user and an artificial intelligence assistant. The assistant gives helpful, detailed, and polite answers to the user's questions. SYSTEM: 您好！請問有什麼我可以幫您的嗎？ USER: ${prompts} ASSISTANT: `, max_new_tokens: 2048 });
        ws.send(message);
    };
    
    // Process incoming messages
    ws.onmessage = (event: any) => {
        const jsonData = JSON.parse(event.data);
        //   console.log(jsonData.text);
        onChatMessage((jsonData?.text || ""), false);
        // Handle the received message in your application logic
    };
    
    // Handle connection closure
    ws.onclose = () => {
        onChatMessage('', true)
        console.log('WebSocket connection closed');
        // Handle closure in your application, e.g., clean up resources
    };
    
};
